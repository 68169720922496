import get from '@invitato/helpers/dist/getLocale';

export default {
  akadGeneral: get('Pemberkatan', ' Holy Matrimony'),
  akadMuslim: get('Akad Nikah','Akad'),
  reception: get('Resepsi', 'Reception'),
  location: get('Lihat Lokasi', 'View Location'),
  descAkadGeneral: get(
    'Tanpa mengurangi rasa hormat, berdasarkan kondisi saat ini pemberkatan hanya akan dihadiri oleh kedua keluarga mempelai.', 
    `Without reducing respect, based on current conditions, holy matrimony will only be attended by the bride and groom's families.`
  ),
  descAkadMuslim: get(
    'Tanpa mengurangi rasa hormat, berdasarkan kondisi saat ini akad hanya akan dihadiri oleh kedua keluarga mempelai.',
    `Without reducing respect, based on current conditions, akad will only be attended by the bride and groom's families.`
  ),
  descAkadInvitationMuslim: get(
    'Tanpa mengurangi rasa hormat, berdasarkan kondisi saat ini akad hanya akan dihadiri oleh kedua keluarga mempelai.',
    `Without reducing respect, based on current conditions, akad will only be attended by the bride and groom's families.`
  ),
  descAkadInvitationGeneral: get(
    'Tanpa mengurangi rasa hormat, berdasarkan kondisi saat ini pemberkatan hanya akan dihadiri oleh kedua keluarga mempelai.',
    `Without reducing respect, based on current conditions, holy matrimony will only be attended by the bride and groom's families.`
  ),
  descReception: get(
    'Kami memohon kehadiran Bapak/Ibu/Saudara/i dalam pelaksanaan resepsi pernikahan.',
    "We request the presence of Mr/Mrs/Ms in the wedding reception."
  ),
};