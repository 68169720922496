import React from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Box, useDisclosure } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import LoveStory from '../LoveStory/Lazy';
import DetailCard from './DetailCard';

import { IMG_MAN, IMG_GIRL, IMG_COVER_LOVESTORY } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_LOVE_STORY } from '@/constants/feature-flags';
import {
  GIRL_NAME_SHORT,
  BOY_NAME_SHORT,
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  IS_BOY_FIRST,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
} from '@/constants';



const TAB_PROPS = {
  ...BUTTON_PROPS,
  textTransform: 'uppercase',
  letterSpacing: '2px',
  fontSize: 'xs',
  color: 'white',
  padding: '8px 12px',
  _selected: {
    borderBottom: '2px solid',
    borderColor: 'white',
  },
};

const loveStory =
  `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`;

const GIRL_PROPS = {
  fullName: GIRL_NAME,
  imgUrl: IMG_GIRL,
  nameShort: GIRL_NAME_SHORT,
  instagramId: IG_GIRL,
  description: GIRL_PARENT_NAME,
};

const BOY_PROPS = {
  fullName: BOY_NAME,
  imgUrl: IMG_MAN,
  nameShort: BOY_NAME_SHORT,
  instagramId: IG_BOY,
  description: BOY_PARENT_NAME,
};

function CoupleInfo({ ...rest }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const lang = useLang();
  const firstProps = IS_BOY_FIRST ? BOY_PROPS : GIRL_PROPS;
  const secondProps = IS_BOY_FIRST ? GIRL_PROPS : BOY_PROPS;

  return (
    <Box bgColor="bgPrimary" padding="32px 0 0 0">
      <Tabs variant="unstyled" padding="0 32px 16px 32px" {...rest}>
        <TabList>
          <Tab {...TAB_PROPS}>The Groom & Bride</Tab>
          {/* <Tab {...TAB_PROPS}>{secondProps.nameShort}</Tab> */}
          {ENABLE_LOVE_STORY && <Tab {...TAB_PROPS}>How We Met</Tab>}
        </TabList>

        <TabPanels padding="16px 0">
          <TabPanel padding="0">
            <DetailCard {...firstProps} />
            <DetailCard {...secondProps} marginTop="16px"/>
          </TabPanel>
          {ENABLE_LOVE_STORY && (
            <TabPanel padding="0">
              <DetailCard
                fullName="Friend First"
                imgUrl={IMG_COVER_LOVESTORY}
                description={loveStory}
                onClickReadMore={onOpen}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <LoveStory onClose={onClose} visible={isOpen} lang={lang} />
    </Box>
  );
}

export default React.memo(CoupleInfo);
