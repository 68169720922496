import React from 'react';
import { Box, Center, Text, Link, Heading, AspectRatio, Divider } from '@chakra-ui/react';

import Image from '@/components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@hooks/useInvitation';
import ImgPattern from '@/assets/pattern/flower.png';

import { INVITATO_URL, SOUND_BY, SOUND_URL, THE_BRIDE } from '@/constants';
import { LOGO_INVITATO, IMG_FOOTER_COVER } from '@/constants/assets';
import { BG_PRIMARY_GRADIENT, BG_PRIMARY_DARK_GRADIENT } from '@/constants/colors';
import { FOOTER_LINK_PROPS } from './types';

import txt from './locales'
import useLang from '@hooks/useLang';
import { ENABLE_COLLABORATION, MUSLIM_INVITATION, SELECTED_VENDOR_COLLABORATION } from '@/constants/feature-flags';

function FooterSection() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary">
      <Box textAlign="center" color="mainColorText" padding="42px 32px 0 32px">
        <WithAnimation>
          <Text color="mainColorText">
          {isInvitation ? (
              <>{txt.penutupInvitation[lang]}</>
            ) : (
              <>{txt.penutupAnnouncement[lang]}</>
            )}
          </Text>
        </WithAnimation>
        {MUSLIM_INVITATION &&<WithAnimation>
          <Text fontSize="md" fontFamily="body"
          size='16px' color="mainColorText">
              Wassalamualaikum Warahmatullahi Wabaraktuh
            </Text>
          </WithAnimation>}
        <WithAnimation left>
          <Center padding="32px 0 0 0">
            <AspectRatio ratio={3 / 4} width="80%">
              <Image src={IMG_FOOTER_COVER} borderRadius="16px" />
            </AspectRatio>
          </Center>
        </WithAnimation>
      </Box>
      <Box
        bgColor="bgPrimary"
        bgSize="initial"
        color="mainColorText"
        textAlign="center"
        paddingBottom="32px"
        bgImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_DARK_GRADIENT}), url(${ImgPattern})`}
      >
        <WithAnimation>
          <Heading transform="translateY(-24px)" fontFamily="headingAlternative">
            {txt.thanks[lang]}
          </Heading>
        </WithAnimation>
        <WithAnimation>
          <Heading marginTop="-6px" size="2xl" fontWeight="normal" marginBottom="0">
            {THE_BRIDE.replace('+', '&')}
          </Heading>
        </WithAnimation>
      </Box>
      {/* Title and Desc Section */}
      <Box bgColor="alternativeDark" textAlign="center" width="100%" maxW="500px">
        <Box padding="24px">
          {/* INVITATO SECTION */}
          <Box>
            <Center>
              <Link href={INVITATO_URL} target="_blank">
                <Image src={LOGO_INVITATO} maxWidth="100px" margin="0" />
              </Link>
            </Center>
            <Text textAlign="center" color="mainColorText" fontSize="xs" margin="-4px 0 12px 0">
              Created with love by Invitato
            </Text>
            {ENABLE_COLLABORATION && (
              <Box margin="-4px 0 18px">
                <Center>
                  <Divider
                    margin="8px 0 16px"
                    maxWidth="130px"
                    opacity="1"
                    height="1px"
                    bgColor="mainColorText"
                    border="none"
                  />
                </Center>
                <Center margin="6px 0 2px">
                  <Link href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`} target="_blank">
                  <Image
                        src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                        {...SELECTED_VENDOR_COLLABORATION.imgProps}
                      />
                  </Link>
                </Center>
                <Center>
                  <Text color="mainColorText" align="center" fontSize="md">
                    In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                  </Text>
                </Center>
              </Box>
            )}
            <Text {...FOOTER_LINK_PROPS}>
              {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
            </Text>
            <Link {...FOOTER_LINK_PROPS} href={SOUND_URL}>
              <Text>{`Song by ${SOUND_BY}`}</Text>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);