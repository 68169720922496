import React from 'react';
import { string } from 'prop-types';
import { Box, Center } from '@chakra-ui/react';

import CountDown from '@/components/Common/CountDown/V3';
import AddToCalendar from '@/components/Common/AddToCalendar';
import WithAnimation from '@/components/Common/WithAnimation';

import ImgPattern from '@/assets/pattern/flower.png';
import { BG_COUNTING_DOWN } from '@/constants/assets';
import { BUTTON_PROPS, BG_PRIMARY_GRADIENT, BUTTON_GRADIENT } from '@/constants/colors';

import txt from './locale';
import useInvitation from '@hooks/useInvitation';

const DEFAULT_BOTTOM = {
  color: 'white',
  boxShadow: 'xl',
  fontSize: '14px',
  borderRadius: '24px',
  textTransform: 'uppercase',
  marginTop: '18px',
  fontWeight: 'light',
  background: BUTTON_GRADIENT,
  ...BUTTON_PROPS,
};

function CountingDown({ lang }) {

  const isInvitation = useInvitation();
  return (
    <Box
      zIndex="3"
      bgColor="bgPrimary"
      bgSize="initial"
      bgImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_GRADIENT}), url(${ImgPattern})`}
      padding="62px 24px"
    >
      <Box height="100%">
        <WithAnimation left>
          <Box
            width="100%"
            height="550px"
            bgImage={`url(${BG_COUNTING_DOWN})`}
            bgSize="cover"
            bgPosition="top"
            borderRadius="16px"
            border="6px solid"
            borderColor="bgSecondary"
          >
            <Box paddingTop="324px">
              <CountDown lang={lang} />
              {isInvitation && (
                <WithAnimation>
                  <Center>
                    <AddToCalendar
                      text={txt.detail[lang]}
                      color="mainColorText"
                      // bgColor="rgb(60, 60, 60, 0.44)"
                      bgColor="bgSecondary"
                      boxShadow="xl"
                      textTransform="uppercase"
                      fontWeight="normal"
                      {...DEFAULT_BOTTOM}
                    />
                  </Center>
                </WithAnimation>
              )}
            </Box>
          </Box>
        </WithAnimation>
      </Box>
    </Box>
  );
}

CountingDown.propTypes = {
  lang: string.isRequired,
};

export default CountingDown;
