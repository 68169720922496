import get from '@invitato/helpers/dist/getLocale';

export default {
  nameayat: get('[Matius 19:5-6]', '[Matthew 19 : 5-6]'),
  ayat: get(
    'Dan firman-Nya: Sebab itu laki-laki akan meninggalkan ayah dan ibunya dan bersatu dengan isterinya, sehingga keduanya itu menjadi satu daging. Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.”',
    "and said, For this reason a man will leave his father and mother and be united to his wife, and the two will become one flesh?' So they are no longer two, but one flesh. Therefore what God has joined together, let no one separate.”",
  ),

  nameayatquran: get('[Q.S. Ar-Ruum : 21]', '[Q.S. Ar- Ruum : 21]'),
  ayatquran: get(
    'Dan di antara tanda-tanda kebesaran-Nya, Dia menciptakan pasangan- pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar- benar terdapat tanda-tanda kebesaran Allah bagi kaum yang berpikir.',
    'And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.',
  ),
};
