import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: {
    id: 'Kepada Bapak/Ibu/Saudara/i,',
    en: 'Dear Mr/Mrs/Ms,',
  },
  user: {
    id: 'Keluarga & Teman-teman',
    en: 'Family & Friends',
  },
  invitationMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    `With the blessing of Allah SWT, we would like to invite you to our wedding ceremony:`,
  ),
  announcementMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `With the blessing of Allah SWT, we would like to announce our wedding ceremony:`,
  ),
  invitation: get(
    `Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    `With the blessing of God, we would like to invite you to our wedding ceremony:`,
  ),
  announcemnt: get(
    `Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `With the blessing of God, we would like to announce our wedding ceremony:`,
  ),
};
