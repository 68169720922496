import React from 'react';
import { string } from 'prop-types';

import { Image, Heading, Box, Center, Button, useDisclosure, AspectRatio } from '@chakra-ui/react';
import TypedAnimation from 'react-type-animation';
import isDevelopment from '@invitato/helpers/dist/isDevelopmentMode';

import LazyImage from '@components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';
import Content from './Content';

import { THE_BRIDE } from '@/constants';
import { IMG_PHOTO_GALLERY, IMG_LOGO_INVERT } from '@/constants/assets';
import { BUTTON_PROPS, BG_PRIMARY, BG_PRIMARY_DARK, BUTTON_GRADIENT } from '@/constants/colors';


import txt from './locales'

const TYPING_DELAY = 3000;
function PhotoSection({ lang }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box
      padding="42px 24px"
      textAlign="center"
      bgColor="bgPrimary"
      color="mainColorText"
      bgImage={`linear-gradient(180deg, ${BG_PRIMARY} 0%, ${BG_PRIMARY_DARK} 200%)`}
    >
      <Box>
        <WithAnimation>
          <Center>
            <Image src={IMG_LOGO_INVERT} width="100%" maxW="100px" />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Heading fontWeight="normal" size="2xl" margin="16px 0">
            {THE_BRIDE.replace('+', '&')}
          </Heading>
        </WithAnimation>
        <Center>
          <Box
            padding="4px 8px"
            bgColor="bgSecondary"
            width="80%"
            borderRadius="8px"
            color="secondaryColorText"
            textTransform="uppercase"
            fontFamily="bodyAlternative"
            fontWeight="light"
            fontSize="16px"
          >
            <TypedAnimation
              cursor={true}
              repeat={isDevelopment() ? false : Infinity}
              wrapper="h3"
              sequence={[
                'Always Forever',
                TYPING_DELAY,
                'Always Loving',
                TYPING_DELAY,
                'Always Carrying',
                TYPING_DELAY,
                'Always Understanding',
                TYPING_DELAY,
              ]}
            />
          </Box>
        </Center>
      </Box>
      <WithAnimation>
        <Center maxHeight="400px" margin="24px 0">
          <AspectRatio margin="16px 0" width="100%" maxW="250px" ratio={3 / 4}>
            <Box
              boxShadow="xl"
              bgSize="contain"
              border="4px solid"
              borderColor="bgSecondary"
              borderRadius="16px"
              width="fit-content !important"
              height="max-content !important"
            >
              <LazyImage
                src={IMG_PHOTO_GALLERY}
                objectPosition="center"
                // borderRadius="16px"
                margin="0 auto"
              />
            </Box>
          </AspectRatio>
        </Center>
      </WithAnimation>
      {/* Button Section */}
      <WithAnimation>
        <Center>
          <Button
            size="sm"
            fontSize="14px"
            margin="24px 0 32px 0"
            fontWeight="light"
            colorScheme="blackAlpha"
            bgColor="bgSecondary"
            color="mainColorText"
            //fontFamily="bodyAlternative"
            background={BUTTON_GRADIENT}
            textTransform="uppercase"
            onClick={() => onOpen()}
            {...BUTTON_PROPS}
          >
            {txt.lihatGalery[lang]}
          </Button>
        </Center>
      </WithAnimation>
      {/* Content gallery */}
      <Content lang={lang} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

PhotoSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(PhotoSection);
