import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';

import { Box, Text, Heading } from '@chakra-ui/react';
import WithAnimation from '@/components/Common/WithAnimation';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import ScrollToDown from '@/components/Common/ScrollToDown/V1';

import { THE_BRIDE } from '@/constants';
import { BUTTON_GRADIENT, TEXT_SHADOW } from '@/constants/colors';
import { BG_COVER } from '@/constants/assets';

import txt from './locale';
import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element
 }
 */
function Cover({ loaded, onSeeDetail }) {
  const lang = useLang();
  const isInvitation = useInvitation();

  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setShowTitle(true);
  }, []);

  return (
    <Box
      minHeight="100vh"
      bgColor="bgPrimary"
      bgImage={`linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(${BG_COVER})`}
      bgSize="cover"
      bgPos="center"
    >
      <Box padding="0" minWidth="250px" minHeight="460px" position={'relative'}>
        {showTitle && (
          <Box
            bottom="4rem"
            position="absolute"
            color="secondaryColorText"
            letterSpacing="1px"
            fontWeight="400"
            fontSize="10px"
            width="100%"
            maxW="500px"
            top="calc(100vh - 220px)"
            height="120px"
            textShadow={`rgba(0, 0, 0, 0.6) 1px 1px`}
          >
            <Box>
              {/* BRIDE SECTION */}
              <Box margin="16px 0">
                {!isInvitation && (
                  <WithAnimation>
                    <Text
                      textAlign="center"
                      color="white"
                      fontFamily="serif"
                      margin="-32px 0 0 0"
                      fontSize="xl"
                    >
                      Announcement
                    </Text>
                  </WithAnimation>
                )}
                <WithAnimation>
                  <Heading
                    textAlign="center"
                    fontSize="28px"
                    color="white"
                    letterSpacing="2px"
                    fontWeight="normal"
                    fontFamily="body"
                    textTransform="uppercase"
                    textShadow={`1px 1px 3px ${TEXT_SHADOW}`}
                    margin="0"
                  >
                    {THE_BRIDE}
                  </Heading>
                </WithAnimation>
              </Box>
              <WithAnimation left>
                <Text
                  textAlign="center"
                  color="white"
                  fontFamily="heading"
                  margin="-24px 0 0 0"
                  fontSize="28px"
                >
                  Always Forever
                </Text>
              </WithAnimation>
            </Box>
            <WithAnimation>
              <ScrollToDown
                loaded={loaded}
                style={{ fontsize: '28px' }}
                loading={loading}
                marginTop="32px"
                text={isInvitation ? txt.invitation[lang] : txt.announcement[lang]}
                bgColor="(bgSecondary 50%)"
                color="white"
                textTransform="uppercase"
                borderRadius="24px"
                background={BUTTON_GRADIENT}
                className=""
                onClick={handleClickDetail}
              />
            </WithAnimation>
          </Box>
        )}
      </Box>
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
