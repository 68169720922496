import React from 'react';
import { string, func } from 'prop-types';
import { Box, Text, Link, Image, AspectRatio } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import WithAnimation from '@/components/Common/WithAnimation';
import ImgPattern from '@/assets/pattern/flower.png';
import { BG_PRIMARY_GRADIENT } from '@/constants/colors';

import txt from './locales';
import { ENABLE_LOVE_STORY_CONTENT } from '@/constants/feature-flags';

function DetailCard({ fullName, imgUrl, instagramId, description, onClickReadMore }) {
  const lang = useLang();

  return (
    <Box color="mainColorText" marginTop="8px">
      <WithAnimation>
        <Box
          padding="24px"
          borderRadius="24px"
          border="4px solid white"
          bgSize="20%"
          bgImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_GRADIENT}), url(${ImgPattern})`}
        >
          <AspectRatio ratio={3 / 4}>
            <Image src={imgUrl} borderRadius="16px" objectPosition={"top"}/>
          </AspectRatio>
        </Box>
      </WithAnimation>
      <WithAnimation>
        <Text fontFamily="cursive" fontSize="2xl" marginTop="16px">
          {fullName}
        </Text>
      </WithAnimation>
      {instagramId && (
        <WithAnimation>
          <Link
            href={`https://instagram.com/${instagramId}`}
            target="_blank"
            textDecoration="underline
          "
          >
            {'@' + instagramId}
          </Link>
        </WithAnimation>
      )}
      <WithAnimation>
        <Text marginTop="16px" marginBottom={"32px"} fontSize="16px" dangerouslySetInnerHTML={{ __html: description }} />
      </WithAnimation>
      {ENABLE_LOVE_STORY_CONTENT && typeof onClickReadMore !== 'undefined' &&(
        <Text
          marginTop="8px"
          textAlign="end"
          fontStyle="italic"
          textDecoration="underline"
          cursor="pointer"
          onClick={onClickReadMore}
        >
          {txt.readMore[lang]}
        </Text>
        )}
    
    </Box>
  );
}

DetailCard.propTypes = {
  fullName: string.isRequired,
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  description: string.isRequired,
  onClickReadMore: func.isRequired,
};

export default React.memo(DetailCard);
