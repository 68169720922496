import React, { useEffect, useState } from 'react';
import { string, oneOfType, object, 
  // bool 
} from 'prop-types';
import { Box, Text, Heading, Center, AspectRatio } from '@chakra-ui/react';
import { SiGooglemaps } from 'react-icons/si';

import LazyImage from '@components/Common/LazyImage';
import WithAnimation from '@components/Common/WithAnimation';
import { BUTTON_PROPS, BUTTON_GRADIENT } from '@/constants/colors';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
// import DownloadQRButton from '@components/Common/Buttons/downloadQR';
// import QRCard from '@components/Common/QRCard';
// import * as ID from '@/constants/identifier';
import QRCardSection from '@components/Common/QRCard/Lazy';
import useGuestInfo from '@hooks/useGuestInfo';
import { ENABLE_QR_INVITATION } from '@/constants/feature-flags';
import { ID_QRCODE } from '@/constants/identifier';
import useLang from '@hooks/useLang';

import txt from './locales';
import BorderFrame from '@components/Common/BorderFrame';

function WeddingDetail(props) {
  const {
    date,
    dresscode,
    imgUrl,
    location,
    locationRoad,
    notes,
    locationUrl,
    QRCODE,
    subTitle,
    time,
    title,
    // isResepsi,
    titleProps,
    ...rest
  } = props;

  const { code } = useGuestInfo();
  const [marginTop, setmarginTop] = useState('20px');
  const [marginBottom, setmarginBottom] = useState('58px');

  const lang = useLang();

  useEffect(() => {
    //if code is not exist, remove margin bottom, because we don't need to show QR Code
    if (!code) {
      setmarginTop('0px');
      setmarginBottom('0px');
    } else {
      setmarginTop('20px');
      setmarginBottom('58px');
    }
  }, [code]);

  return (
    <Box color="alternativeColorText" marginBottom="24px" {...rest}>
      <Box
        padding="0 32px"
        transform="translateY(100px)"
        marginTop="-100px"
        zIndex={2}
        pos={'relative'}
      >
        <AspectRatio ratio={3 / 4}>
          <LazyImage src={imgUrl} borderTopRadius="50%" borderBottomRadius="4px" objectPosition="top"/>
        </AspectRatio>
        <WithAnimation>
          <Heading
            margin="0"
            marginTop="-62px"
            textAlign="center"
            size="2xl"
            fontWeight="normal"
            color="mainColorText"
            {...titleProps}
          >
            {title}
          </Heading>
        </WithAnimation>
      </Box>
      {/* Bottom Sections */}
      <Box
        bgColor="bgSecondary"
        padding="120px 24px 42px 24px"
        borderRadius="16px"
        zIndex={1}
        pos={'relative'}
      >
        <BorderFrame isRSVPFrame isHeader />
        <BorderFrame isRSVPFrame />
        <WithAnimation>
          <Box margin="16px 0" fontSize="14px" textAlign="center" textTransform="uppercase">
            <Text fontWeight="bold">{date}</Text>
            <Text fontWeight="bold">{time}</Text>
            {dresscode && (
              <Text fontWeight="bold" fontSize="14px">{`${dresscode}`}</Text>
            )}
          </Box>
        </WithAnimation>
        {notes && (
          <WithAnimation>
            <Text margin="8px 0" fontSize="14px" textAlign="center" textTransform="uppercase">
              <span dangerouslySetInnerHTML={{ __html: notes }} />
            </Text>
          </WithAnimation>
        )}
        {location && (
          <WithAnimation>
            <Text fontSize="14px" fontWeight="bold" textAlign="center" textTransform="uppercase">
              <span dangerouslySetInnerHTML={{ __html: location }} />
            </Text>
          </WithAnimation>
        )}
        {locationRoad && (
          <WithAnimation>
            <Text fontSize="14px" fontWeight="bold" textAlign="center" textTransform="uppercase">
              {locationRoad}
            </Text>
          </WithAnimation>
        )}
        <WithAnimation>
          <Text margin="32px 0" fontSize="14px" textAlign="center" textTransform="uppercase">
            <span dangerouslySetInnerHTML={{ __html: subTitle }} />
          </Text>
        </WithAnimation>
        {locationUrl && !ENABLE_QR_INVITATION && (
          <WithAnimation>
            <Center>
              <ViewLocationButton
                bgColor="bgSecondary"
                color="mainColorText"
                marginTop="8px"
                boxShadow="xl"
                fontSize="14px"
                fontWeight="light"
                textTransform="uppercase"
                borderRadius="24px"
                text={txt.location[lang]}
                leftIcon={<SiGooglemaps size={20} />}
                background={BUTTON_GRADIENT}
                {...BUTTON_PROPS}
              />
            </Center>
          </WithAnimation>
        )}
        {locationUrl && ENABLE_QR_INVITATION && (
          <WithAnimation>
            <Center>
              <ViewLocationButton
                bgColor="bgSecondary"
                color="mainColorText"
                marginTop="8px"
                boxShadow="xl"
                fontSize="14px"
                fontWeight="light"
                textTransform="uppercase"
                borderRadius="24px"
                text={txt.location[lang]}
                leftIcon={<SiGooglemaps size={20} />}
                background={BUTTON_GRADIENT}
                {...BUTTON_PROPS}
              />
            </Center>
          </WithAnimation>
        )}
        {ENABLE_QR_INVITATION && QRCODE && (
          <WithAnimation>
            <Center>
              <Box marginBottom={marginBottom} marginTop={marginTop}>
                {code && <QRCardSection name={ID_QRCODE} />}
                {/* <Center m="5">
                  <Stack direction="row" spacing={4} align="center">
                    <DownloadQRButton/>
                  </Stack>
                </Center> */}
              </Box>
            </Center>
          </WithAnimation>
        )}

        <Box pos={'relative'} style={{ pointerEvents: 'none' }}>
          <BorderFrame isRSVPFrame isFooter />
        </Box>
      </Box>
    </Box>
  );
}

WeddingDetail.propTypes = {
  date: string.isRequired,
  dresscode: string,
  imgUrl: string.isRequired,
  location: string,
  locationRoad: string,
  notes: string,
  subTitle: oneOfType([string, object]),
  locationUrl: string.isRequired,
  QRCODE: string,
  time: string.isRequired,
  title: string.isRequired,
  titleProps: object,
  // isResepsi: bool,
};

WeddingDetail.defaultProps = {
  notes: '',
  dresscode: '',
  titleProps: {},
  location: '',
  locationRoad: '',
  // isResepsi: false,
};

export default React.memo(WeddingDetail);
