import React from 'react';
import { string } from 'prop-types';

import { Box } from '@chakra-ui/react';
import Detail from './Detail';

import ImgPattern from '@/assets/pattern/flower.png';
import { BG_PRIMARY_DARK_GRADIENT, BG_PRIMARY_GRADIENT } from '@/constants/colors';
import { IMG_AKAD_COVER, IMG_RESEPSI_COVER } from '@/constants/assets';
import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_TIME,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
} from '@/constants';

import txtWording from './locales';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
import useInvitation from '@hooks/useInvitation';
import QRCardSection from '@components/Common/QRCard/Lazy';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';
import useType from '@hooks/useType';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ lang, ...rest }) {
  const isInvitation = useInvitation();
  const typeValue = useType()
  return (
    <Box
      bgColor="bgPrimary"
      bgSize="initial"
      bgImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_DARK_GRADIENT}), url(${ImgPattern})`}
      padding="42px 24px"
      {...rest}
    >
      {MUSLIM_INVITATION && (typeValue === "1" || typeValue === "3" || !isInvitation) &&(
        <Detail
          imgUrl={IMG_AKAD_COVER}
          title={txtWording.akadMuslim[lang]}
          subTitle={
            isInvitation
              ? txtWording.descAkadInvitationMuslim[lang]
              : txtWording.descAkadMuslim[lang]
          }
          date={WEDDING_AKAD_FULLDATE}
          time={WEDDING_AKAD_TIME}
          location={isInvitation && WEDDING_AKAD_LOC_NAME}
          locationRoad={isInvitation && WEDDING_AKAD_LOC_ROAD}
          lang={lang}
          name="akad-section"
          locationUrl={isInvitation && ViewLocationButton}
        />
      )}
      {!MUSLIM_INVITATION && (
        <Detail
          imgUrl={IMG_AKAD_COVER}
          title={txtWording.akadGeneral[lang]}
          subTitle={
            isInvitation
              ? txtWording.descAkadInvitationGeneral[lang]
              : txtWording.descAkadGeneral[lang]
          }
          date={WEDDING_AKAD_FULLDATE}
          time={WEDDING_AKAD_TIME}
          location={isInvitation && WEDDING_AKAD_LOC_NAME}
          locationRoad={isInvitation && WEDDING_AKAD_LOC_ROAD}
          lang={lang}
          name="akad-section"
          
        />
      )}
      {isInvitation &&  typeValue === "3"  && <Box height="62px" />}
      {isInvitation && (typeValue === "2" || typeValue === "3" )  && (
        <Detail
          imgUrl={IMG_RESEPSI_COVER}
          title={txtWording.reception[lang]}
          subTitle={txtWording.descReception[lang]}
          date={WEDDING_RESEPSI_FULLDATE}
          time={WEDDING_RESEPSI_TIME}
          location={WEDDING_RESEPSI_LOC_NAME}
          locationRoad={WEDDING_RESEPSI_LOC_ROAD}
          //...rest qr code
          QRCODE={QRCardSection}
          locationUrl={ViewLocationButton}
          name="resepsi-section"
          dresscode='Diawali dengan Tradisi Upacara Pedang Pora'
        />
      )}
    </Box>
  );
}

WeddingSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(WeddingSection);