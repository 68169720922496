import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * custom hooks to return shift time type
 * @return {string}
 */
function useType() {
  const location = useLocation();
  const typeType = getQueryValue(location, 'q');

  return typeType;
}

export default useType;
