import React from 'react';
import { RSVP_BORDER, WEDDING_BORDER } from './BorderSvg';
import { Box } from '@chakra-ui/react';
import { bool } from 'prop-types';

function BorderFrame({ isRSVPFrame, isWeddingFrame, isHeader, isFooter, ...rest }) {
    if (isWeddingFrame){
        return (
            <Box 
                width={isFooter ? "calc(100% + 48px)" :"100%"} 
                height={isHeader || isFooter ? "42px" : "calc(100% - 104px)"}
                bgSize={"100%"}
                bgRepeat="repeat-y"
                bgImage={`url("data:image/svg+xml;utf8,${encodeURIComponent(WEDDING_BORDER)}")`} 
                bgPos={isHeader ? "top" : isFooter ? "bottom" : "center -24px"} 
                position="absolute" 
                top={isHeader || isFooter ? "0" : "42px"}
                left={isFooter ? "-24px" : "0"}
                {...rest}
            />
        )
    }
    if(isRSVPFrame){
        return (
            <Box 
                width={isFooter ? "calc(100% + 24px)" : "calc(100% - 24px)"}
                height={isHeader || isFooter ? "60px" : "calc(100% - 144px)"}
                bgSize={"100%"}
                bgRepeat="repeat-y"
                bgImage={`url("data:image/svg+xml;utf8,${encodeURIComponent(RSVP_BORDER)}")`}
                bgPos={isHeader || isFooter ? "top" : "center -66px"} 
                position="absolute" 
                top={isHeader ? "12px" : isFooter ? "-30px" : "72px"}
                left={isFooter ? "-12px" : "12px"}
                transform={isFooter ? "rotate(180deg)": ""  }
                {...rest}
            />
        )
    }
    return (
        <Box
            width={isHeader ? "100%" : isFooter ? "calc(100% + 32px)" : "calc(100% + 32px)"} // If upper box have 24px padding (right & left) add 48px
            height={isHeader || isFooter ? "42px" : "100%"}
            bgSize={"100%"}
            bgRepeat="repeat-y"
            bgImage={`url(${RSVP_BORDER})`} 
            bgPos={isHeader ? "top" : isFooter ? "bottom" : "center -24px"} // If change frame fiddle with "center -24px" try change to like "center -32px" 
            position="absolute" 
            top={isFooter? "" : "0"}
            {...rest}
        />
    )
}

BorderFrame.propTypes = {
    isHeader: bool,
    isFooter: bool,
    isWeddingFrame: bool,
    isRSVPFrame: bool,
};

BorderFrame.defaultProps = {
    isHeader: false,
    isFooter: false,
    isWeddingFrame: false,
    isRSVPFrame: false,
};

export default BorderFrame;
