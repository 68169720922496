import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import LazyImage from '@components/Common/LazyImage';

import { IMG_QURAN, IMG_LOGO } from '@/constants/assets';
import { BG_PRIMARY, BG_PRIMARY_DARK } from '@/constants/colors';

import txtWording from './locale';
import useLang from '@hooks/useLang';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';

function FooterSection() {
  const lang = useLang();
  return (
    <Box
      textAlign="center"
      bgColor="bgPrimary"
      bgImage={`linear-gradient(180deg, ${BG_PRIMARY} 0%, ${BG_PRIMARY_DARK} 200%)`}
      padding="82px 42px"
    >
      {/* Title and Desc Section */}
      <LazyImage
        borderTopRadius="50%"
        src={IMG_QURAN}
        width="100%"
        height="400px"
        objectFit="cover"
        objectPosition="center"
      />
      <Box bgColor="bgSecondary" padding="24px" borderBottomRadius="24px">
        <WithAnimation>
          <Center>
            <LazyImage src={IMG_LOGO} maxWidth="100px" margin="16px 0 24px 0" />
          </Center>
        </WithAnimation>
        {!MUSLIM_INVITATION && (
          <WithAnimation>
            <Text fontSize="16px" fontFamily="body" color="alternativeColorText" fontWeight="300">
              {txtWording.ayatquran[lang]}
            </Text>
          </WithAnimation>
        )}

        {MUSLIM_INVITATION && (
          <WithAnimation>
            <Text fontSize="16px" fontFamily="body" color="alternativeColorText" fontWeight="300">
              {txtWording.ayatquran[lang]}
            </Text>
          </WithAnimation>
        )}
        {!MUSLIM_INVITATION && (
          <WithAnimation>
            <Text fontSize="16px" fontFamily="body" color="alternativeColorText" fontWeight="300">
              {txtWording.nameayatquran[lang]}
            </Text>
          </WithAnimation>
        )}
        {MUSLIM_INVITATION && (
          <WithAnimation>
            <Text fontSize="16px" fontFamily="body" color="alternativeColorText" fontWeight="300">
              {txtWording.nameayatquran[lang]}
            </Text>
          </WithAnimation>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);
