import React from 'react';
import { number, string } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

function CountItem({ number, text }) {
  return (
    <Box
      margin="4px"
      padding="12px 4px"
      width="60px"
      height="75px"
      // bgColor="rgb(60, 60, 60, 0.44)"
      bgColor="bgPrimary"
      borderRadius="8px"
      textAlign="center"
    >
      <Box>
        <Text fontSize="2xl" color="mainColorText">
          {number}
        </Text>
      </Box>
      <Box>
        <Text color="mainColorText" fontSize="sm" marginTop="-4px">
          {text}
        </Text>
      </Box>
    </Box>
  );
}

CountItem.propTypes = {
  number: number.isRequired,
  text: string.isRequired,
};

export default React.memo(CountItem);
