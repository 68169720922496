import React from 'react';
import { Box, Heading, Text, Divider, Center } from '@chakra-ui/react';

import useGuestInfo from '@/hooks/useGuestInfo';
import useInvitation from '@/hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';
import ImgPattern from '@/assets/pattern/flower.png';

import { WEDDING_RESEPSI_DATE, GIRL_NAME, BOY_NAME, IS_BOY_FIRST } from '@/constants';
import {
  BG_PRIMARY,
  BG_PRIMARY_DARK,
  BG_PRIMARY_DARK_GRADIENT,
  BG_PRIMARY_GRADIENT,
} from '@/constants/colors';

import txt from './locale';
import useLang from '@hooks/useLang';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';
import BorderFrame from '@components/Common/BorderFrame';

function WelcomeV4() {
  const { name } = useGuestInfo();
  const isInvitation = useInvitation();
  const firstName = IS_BOY_FIRST ? BOY_NAME : GIRL_NAME;
  const secondName = IS_BOY_FIRST ? GIRL_NAME : BOY_NAME;

  const lang = useLang();

  return (
    <Box
      bgColor="bgPrimary"
      bgImage={`linear-gradient(0deg, ${BG_PRIMARY} 0%, ${BG_PRIMARY_DARK} 200%)`}
    >
      <Box
        padding="62px 24px"
        bgSize="initial"
        bgImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_DARK_GRADIENT}), url(${ImgPattern})`}
        backgroundRepeat="repeat"
      >
        <Box
          padding="62px 24px"
          pos={"relative"}
        >
          <BorderFrame isWeddingFrame isHeader />
          <BorderFrame isWeddingFrame/>
          {/* Dear Section */}
            <WithAnimation>
              <Box>
                <Text textAlign="center" fontWeight="light" color="mainColorText" fontFamily="serif" fontSize="16px">
                  {txt.dear[lang]}
                </Text>
                <Text color="mainColorText" textAlign="center" fontFamily="serif" fontWeight="bold" fontSize="16px">
                  {name ? name : `${txt.user[lang]}`}             
                </Text>
              </Box>
            </WithAnimation>
          {/* Greetings Text */}
          <Box padding="24px 0" >
            <WithAnimation>
              <Text fontFamily="body" color="mainColorText" fontSize="16px" textAlign="center">
              {MUSLIM_INVITATION && (
                <>
                  Assalamualaikum Warahmatullahi Wabarakatuh.
                  <br />
                  {isInvitation ? (
                    <>{txt.invitationMuslim[lang]}</>
                  ) : (
                    <>{txt.announcementMuslim[lang]}</>
                  )}
                </>
              )}
              {!MUSLIM_INVITATION && (
                <>{isInvitation ? <>{txt.invitation[lang]}</> : <>{txt.announcemnt[lang]}</>}</>
              )}
              </Text>
            </WithAnimation>
            <Center>
              <Divider
                orientation="vertical"
                opacity="1"
                height="50px"
                color="mainColorText"
                borderLeftWidth="1px"
                borderColor="white"
                marginTop="32px"
              />
            </Center>
          </Box>
            <WithAnimation>
              <Box paddingBottom="24px" textAlign="center" color="mainColorText">
                <Heading size="xl" fontWeight="normal">
                  {firstName}
                </Heading>
                <Heading fontWeight="normal" margin="16px 0" size="2xl">{`&`}</Heading>
                <Heading size="xl" fontWeight="normal">
                  {secondName}
                </Heading>
                <Text fontSize="16px" marginTop="16px" textAlign="center" color="mainColorText">
                  {WEDDING_RESEPSI_DATE}
                </Text>
              </Box>
            </WithAnimation>
          <Box pos={"relative"}>
            <BorderFrame isWeddingFrame isFooter/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WelcomeV4);
