import React from 'react';
import loadable from '@loadable/component';

import { bool, func, string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  Heading,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Text,
  AspectRatio,
} from '@chakra-ui/react';

import { BG_PHOTO_CONTENT } from '@/constants/assets';
import { THE_BRIDE, YOUTUBE_PREWEDDING_EMBED } from '@/constants';
import { ENABLE_PREWEDDING_VIDEO } from '@/constants/feature-flags';
import { BUTTON_PROPS, BUTTON_GRADIENT } from '@/constants/colors';

import { photos as photos1 } from './api/photos-1';
import { photos as photos2 } from './api/photos-2';
import { photos as photos3 } from './api/photos-3';
import { photos as photos4 } from './api/photos-4';

import txt from './locales';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ '@/components/Common/PhotoGallery'),
);

function PhotoContent({ lang, isOpen, onClose }) {
  const renderGallery = (quotes, galleryData) => {
    return (
      <Box padding="24px 24px 0 24px" marginTop="-42px">
        <Text margin="24px 0 0 0" color="alternativeColorText" fontFamily="bodyAlternative">
          {quotes.title}
        </Text>
        <Text margin="12px 0">{quotes.text}</Text>
        <Heading marginBottom="24px" fontFamily="heading" size="lg" fontWeight="normal">
          {`- ${quotes.author} -`}
        </Heading>
        {isOpen && <LoadableGallerry data={galleryData} />}
      </Box>
    );
  };

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" {...BUTTON_PROPS} />
        <DrawerBody width="100%" padding="0" bgColor="bgSecondary">
          <Box bgImage={`url(${BG_PHOTO_CONTENT})`} height="300px" bgSize="cover" bgPosition="top center" />
          <Box marginTop="-90px">
            <Heading
              textAlign="center"
              fontSize="3xl"
              color="mainColorText"
              letterSpacing="2px"
              fontWeight="normal"
              fontFamily="body"
              textTransform="uppercase"
              // textShadow={`1px 1px 1px ${TEXT_SHADOW}`}
              margin="0"
            >
              {THE_BRIDE}
            </Heading>
            <Text
              textAlign="center"
              color="mainColorText"
              fontFamily="heading"
              margin="-8px 0 0 0"
              fontSize="3xl"
            >
              Always Forever
            </Text>
          </Box>
          {/* Images Cover */}
          <Box padding="42px 24px" textAlign="center">
            {ENABLE_PREWEDDING_VIDEO &&
              <Box padding="12px 0 32px">
                <Text>
                  Commitment is what transforms a promise into a reality.
                </Text>
                <Heading margin="12px 0 20px" fontFamily="heading" size="lg" fontWeight="normal">
                  {`- Abraham Lincoln -`}
                </Heading>
                <Box marginTop="16px" padding="0 20px 0 20px">
                  <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg" boxShadow="xl">
                    <iframe
                      title={`Prewedding of ${THE_BRIDE}`}
                      src={YOUTUBE_PREWEDDING_EMBED}
                      style={{ borderRadius: '16px' }}
                    />
                  </AspectRatio>
                </Box>
              </Box>
            }
            {renderGallery(
              {
                author: 'Maya Angelou',
                text: 'Have enough courage to trust love one more time and always one more time.',
                // title: 'ABOUT TRUST',
              },
              photos1,
            )}
            {false && renderGallery(
              {
                author: 'Abraham Lincoln',
                text: 'Commitment is what transforms a promise into a reality.',
                // title: 'ABOUT COMMITMENT',
              },
              photos2,
            )}
            {false && renderGallery(
              {
                author: 'Ichael Bassey Johnson',
                text: 'True love is not a hide and seek game: in true love, both lovers seek each other.',
                // title: 'ABOUT INTIMACY',
              },
              photos3,
            )}
            {false && renderGallery(
              {
                author: 'Heather Wolf',
                text: 'There is no such thing as tough love. Love is kind, love is compassionate, love is tender.',
                // title: 'ABOUT COMPASSION',
              },
              photos4,
            )}
          </Box>
          <Center paddingBottom="62px">
            <Button
              size="md"
              fontWeight="light"
              color="mainColorText"
              onClick={() => onClose()}
              boxShadow="xl"
              background={BUTTON_GRADIENT}
              {...BUTTON_PROPS}
            >
              {txt.back[lang]}
            </Button>
          </Center>
          <Box height="30px" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

PhotoContent.propTypes = {
  lang: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;